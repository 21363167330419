import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// Css
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/swiper/swiper-bundle.min.css';
// // import '../node_modules/magnific-popup/dist/magnific-popup.css';
// import '../node_modules/react-rangeslider/lib/index.css';

// import "./assets/vendor/bootstrap/css/bootstrap.min.css";
// import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
// import "./assets/vendor/aos/aos.css";
// import "./assets/vendor/glightbox/css/glightbox.min.css";
// import "./assets/vendor/swiper/swiper-bundle.min.css";
// import './assets/css/main.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
