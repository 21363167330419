import React, {Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Gallery = React.lazy(() => import("./components/pages/Gallerie"));
const GalleryVideo = React.lazy(() => import("./components/pages/Gallerievideo"));
const Program = React.lazy(() => import("./components/pages/Programme"));
const Contacts = React.lazy(() => import("./components/pages/Contacts"));
const Offres = React.lazy(() => import("./components/pages/Offres"));
const OffresCom = React.lazy(() => import("./components/pages/OffresCom"));
const News = React.lazy(() => import("./components/pages/Info"));
const Newsdetail = React.lazy(() => import("./components/pages/Info-details"));
const Register = React.lazy(() => import("./components/pages/Reservation"));

function App() {
  return (
    <Router basename={''}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contacts} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/galleryvideo" component={GalleryVideo} />
          <Route path="/program" component={Program} />
          <Route path="/blog" component={News} />
          <Route path="/offres" component={Offres} />
          <Route path="/offresCom" component={OffresCom} />
          <Route path="/blog-details" component={Newsdetail} />
          <Route path="/reserver" component={Register} />
          <Route path="/error" component={Error} />
          <Route component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
